export default {
  de: {
    hello: 'hello world',
    world: '{} world',
    apple: 'no apples | one apple | {count} apples'
  },
  fr: {
    hello: 'Bonjour le monde',
    world: '{} le monde',
    apple: 'aucune pomme | une pomme | {count} pommes'
  }
}
