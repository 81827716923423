<template>
  <b-card
    tag="article"
    no-body
    :class="[videoCardOver, StyleClass.videoCard]"
    class="wow bounceInRight"
    bg-variant="dark"
    text-variant="white"
    @mouseover="onMouseOver(item)"
    @click="onClick(item)"
    @mouseout="onMouseOut(item)"
  >
    <b-card-img fluid :src="item.snippet.thumbnails.medium.url" class="rounded-0 video-card-img"></b-card-img>
    <div v-if="isPlaying" class="item-playing">
      <span> {{ $t('page_video.section_videos.en_cours_de_lecture') }} </span>
    </div>
    <div v-else class="contenair_btn_play">
      <b-img
        center
        :src="item.isOver?`${publicPath}over_play.png`:`${publicPath}normal_play.png`"
        width="128"
        class="m-play"
      />
    </div>
    <div class="container-card-title wow zoomInDown" data-wow-duration="1s" >
      <b-card-title tag="h3"  class="text-center family-zilla">{{item.snippet.title}}</b-card-title>
    </div>
  </b-card>
</template>
<script>
export default {
  props: {
    item: {},
    currentItem: {}
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      StyleClass: {
        videoCard: 'video-card'
      }
    }
  },
  computed: {
    videoCardOver () {
      return this.item.isOver ? 'video-card-over' : ''
    },
    isPlaying () {
      if (!this.currentItem) return false
      return this.item.id === this.currentItem.id
    }
  },
  methods: {
    onMouseOver (item) {
      this.$set(item, 'isOver', true)
      this.$emit('mouseOver', item)
    },
    onMouseOut (item) {
      this.$set(item, 'isOver', false)
      this.$emit('mouseOut', item)
    },
    onClick (item) {
      this.$emit('click', item)
    }
  }
}
</script>

<style  lang="scss" scoped>
@import "./src/styles/mainStyle.scss";
.container-card-title {
  display: flex;
  margin: auto;
}
.card-title {
  @include family-zilla;
  padding: 0px 15px;
}

.contenair_btn_play {
  position: absolute;
  width: 100%;
  height: 71%;
  display: flex;
  align-items: center;
}
.video-card {
  height: 203px;
  border-radius: unset !important;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
  h5 {
    background: white;
    color: #343a40;
    margin: 0px;
    padding: 10px;
    @include family-zilla;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 179px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    height: 230px;
  }
}
.video-card-over {
  background-color: #495057 !important;
  h5 {
    background: #f0f0f0 !important;
  }
  .item-playing{
      background: #000000ad !important;
  }
}
.item-playing {
  width: 100%;
  height: 71%;
  position: absolute;
  background: #000000b3;
  z-index: 9999;
  text-align: center;
  align-items: center;
  display: grid;
  transition: all 200ms ease;
  span {
    font-size: 1.2em;
    @include family-zilla;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    height: 55%;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    height: 83%;
  }
}
</style>
