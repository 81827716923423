export default {
  state: {
    isNavLoaded: false
  },
  mutations: {
    SET_IS_NAV_LOADED (state, payload) {
      state.isNavLoaded = payload
    }
  },
  getters: {
    getIsNavLoaded (state) {
      return state.isNavLoaded
    }
  }
}
