import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import messages from "./lang/messages";
import dateTimeFormats from "./lang/dateTimeFormats";
import numberFormats from "./lang/numberFormats";
import VueI18n from "vue-i18n";
import bootstrapVue from "bootstrap-vue";
import VueTruncate from "vue-truncate-filter";
import VueMeta from "vue-meta";
import VueProgressBar from "vue-progressbar";
// import WOW from 'wow.js'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./styles/mainStyle.scss";
import "animate.css";

const options = {
  color: "#30591a",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, options);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(VueI18n);
Vue.use(bootstrapVue);
Vue.use(VueTruncate);
Vue.use(VueMeta);
axios.defaults.baseURL = "https://www.googleapis.com/youtube/v3/";

let navigatorLanguage;

if (
  navigator.language.split("-")[0] != "fr" &&
  navigator.language.split("-")[0] != "de"
) {
  navigatorLanguage = "fr";
} else {
  navigatorLanguage = navigator.language.split("-")[0];
}

const locale = localStorage.getItem("lang") || navigatorLanguage;

const i18n = new VueI18n({
  fallbackLocale: "fr",
  locale: locale,
  messages,
  dateTimeFormats,
  numberFormats,
});
// new WOW().init()

new Vue({
  router,
  store,
  i18n,
  ...App,
  render: (h) => h(App),
}).$mount("#app");
