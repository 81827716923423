<template>
  <b-container fluid class="NavHeader">
    <b-row>
      <b-navbar toggleable="sm" type="dark" variant="transparent" class=" nav-bar-top">
        <b-navbar-brand :to="{name:'home'}" >
          <b-img :src="`${publicPath}${logo}`" class="m-logo  bounceInUp"
          :class="{'wow':IsNavLoaded}"
          data-wow-duration="2s"
           left />
        </b-navbar-brand>
        <h1
          class="h3-bold m-title d-none d-sm-block bounceInRight"
          :class="{'wow':IsNavLoaded}"
          v-html="$t('entete.titre')"
          data-wow-duration="2s"
          data-wow-delay="1s"
        ></h1>
        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->
        <div class="dropdowns-container d-flex flex-wrap align-items-center ml-sm-auto ml-0 justify-content-end">
          <b-nav-item-dropdown  :text="$t('entete.options.videos')" right class="nav-drop">
            <b-dropdown-item
              :to="{ name:'video', params: { id:item.snippet.resourceId.videoId,slug:getslug(item.snippet.title) }}"
              v-for="item in listItem"
              :key="item.id"
              @click="onClick(item)"
            >
              <b-img :src="`${publicPath}normal_play_menu.png`" />
              {{item.snippet.title}}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-dropdown right class="dropdown-langs nav-drop ml-3">
            <template #button-content>
              <p class="mb-0 selected-lang">
                <span class="fa fa-language mr-2"></span>
                {{ $i18n.locale }}
                <i class="fa fa-caret-down ml-2" aria-hidden="true"></i>
              </p>
            </template>
            <b-dropdown-item
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :value="lang.value"
              @click="onChangeLang(lang.value)"
            >
              {{ lang.text }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

       <!-- <b-collapse id="nav-collapse" is-nav class="containe-nav-item">
          <b-navbar-nav class="ml-md-auto ml-0">
            <b-nav-item active>
              <router-link :to="{ name: 'home' }">Vidéos</router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>-->
      </b-navbar>
    </b-row>
    <b-row class="d-block d-sm-none text-center">
        <h1 class="h3-bold m-title" v-html="$t('entete.titre')"></h1>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['title', 'listItem'],
  data () {
    return {
      publicPath: process.env.BASE_URL,
      IsNavLoaded: false,
      langs: [
        { text: 'Francais', value: 'fr' },
        { text: 'Deutsch', value: 'de' }
      ]
    }
  },
  mounted () {
    if (!this.getIsNavLoaded()) {
      this.IsNavLoaded = true
      this.SET_IS_NAV_LOADED(true)
    } else this.IsNavLoaded = false
  },
  computed: {
    logo () {
      return `Logo-${this.$i18n.locale}.png`
    }
  },
  methods: {
    ...mapGetters(['getIsNavLoaded']),
    ...mapMutations(['SET_ITEM', 'SET_IS_NAV_LOADED']),
    getslug (value) {
      value = value.toLowerCase().replace(/ |’|'/g, '-').replace(/à/g, 'a')
      return value
    },
    onClick (item) {
      this.SET_ITEM(item)
    },
    onChangeLang (lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./src/styles/mainStyle.scss";
.nav-bar-top{
    width: 100%;
}
.m-logo {
  width: 100px;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  }
}
.nav-drop img{
  width: 17px;
}
.NavHeader a {
    color: white !important;
    @include family-zilla;
    font-size: 1.2em !important;
}

.containe-nav-item {
  a {
    color: white;
    @include family-zilla;
    font-size: 1.2em;
  }
}

.m-title  {

  font-size: 1.8em;
  font-family: $familyZilla;
  color:$titleColor;
  display: block;
  width: 100%;
  font-weight: bold;
  text-shadow:  3px 3px 3px #000000;
  transform: rotate(-10deg);
  margin-top: -5%;
  transition: all 200ms ease;
  @media only screen and (min-device-width: 320px) and (max-device-width: 575px) {
      font-size: 2em;
      width: 100%;
      margin-top: 0px;

  }
  @media only screen and (min-device-width: 575px) and (max-device-width: 1024px) {
    font-size: 1.2em;
    width: 45%;
  }
  @media only screen and (min-device-width: 1024px) {
    width: 58%;
  }
  @media only screen and (min-width : 1824px) {
     transform: rotate(-5deg);
     margin-top: 0px;
  }
}
.m-title .h3-bold {
  font-weight: bold;
  font-family: $familyZilla;
}
.dropdowns-container {
  width: 120px;
  position: relative;
  top: -25px;
  flex-direction: column-reverse;
  @media only screen and (min-width : 667px) {
    width: auto;
    top: 0px;
    flex-direction: unset;
  }
  .dropdown-langs {
    position: relative;
    top: 27px;
    right: 3px;
    @media only screen and (min-width : 667px) {
      margin-top: 19px;
      top: unset;
      right: unset;
    }
    .selected-lang {
      text-transform: uppercase;
    }
    .fa-arrow-down, .fa-caret-down {
      font-size: 10px;
    }
    &::v-deep {
      .dropdown-toggle {
        @include family-zilla;
        background-color: #343a40;
        border-color: #343a40;
        &::after {
          display: none;
        }
      }
    }
  }
}

</style>
