export default {
  state: {
    item: {}
  },
  mutations: {
    SET_ITEM (state, payload) {
      state.item = payload
    }
  },
  getters: {
    getItem (state) {
      return state.item
    }
  }

}
