export default {
  state: {
    listData: [
      {
        id: '',
        content: ''
      },

      {
        id: 'lbUVM7EqP6Q',
        content: `

        <h4 style="font-size: 1.3em;"><strong>Les dates de consommation des produits alimentaires</strong></h4>

        <p><strong>Date limite de consommation</strong> (<em>à consommer jusqu’au...</em>)&nbsp;<br/> La mention est obligatoire pour les aliments qui doivent être réfrigérés. Les produits sensibles tels le poisson, la viande, les mets aux oeufs crus, etc. doivent être consommés à la date indiquée.</p> <br/>
       <p> <strong>Date de durée de conservation minimale</strong> (<em>à consommer de préférence avant le&nbsp;/ jusqu’au&nbsp;/ avant fin...</em>)&nbsp;<br/> Elle accompagne les aliments qui ne nécessitent pas de réfrigération: pâtes, riz, chocolat, conserves, etc. Après la date indiquée, le goût et les qualités nutritives peuvent se dégrader, mais l'aliment peut généralement être consommé sans risque.</p><br/>
       <p> <strong>Date de vente&nbsp;</strong>&nbsp; <br/>Elle sert uniquement à la logistique des grands distributeurs, sans base légale. Un produit qui a dépassé la date de vente – mais pas la date limite de consommation – peut être consommé sans danger.</p>
       <br/>
        <h4>LIENS UTILES</h4>
      <p>
        Mieux comprendre les dates limites -
        <a  href="https://www.frc.ch/mieux-comprendre-les-dates-limites/" target="_blank" rel="noopener"><span class="mini">frc.ch</span></a>
        <br>
        Trucs et astuces pour éviter de jeter les aliments -
        <a  href="http://foodwaste.ch/trucs-astuces/?lang=fr" target="_blank" rel="noopener"><span class="mini">foodwaste.ch</span></a>
        </p>
        `,
        footerImage: 'image_footer/halte_au_gaspillage.png'
      },
      {
        id: 'Uhp6d7m-ndw',
        footerImage: 'image_footer/halte_au_gaspillage.png'
      },

      {
        id: '9y1Q7o4gTv8',
        content: `
        <h4 style="font-size: 1.3em;"><strong>À la ferme ou au marché</strong> </h4>
        <p>Voici quelques adresses (liste non exhaustive) où se pratiquent la vente directe de produits frais ou transformés, ainsi que d'autres systèmes de distribution, tels les paniers fermiers, l'auto-cueillette ou le self-service&thinsp;:</p>
        <p> <a  href="http://www.agirinfo.com" target="_blank" rel="noopener">agirinfo.com</a></p>
        <p> <a  href="http://www.a-la-ferme.ch" target="_blank" rel="noopener">a-la-ferme.ch</a></p>
        <p> <a  href="http://www.marchepaysan.ch" target="_blank" rel="noopener">marchepaysan.ch</a></p>
        <p> <a  href="http://www.paysgourmand.ch" target="_blank" rel="noopener">paysgourmand.ch</a></p>
        <p> <a  href="http://www.gemuese.ch/Legumes/Saisonnalite-et-regionalite/Vente-a-la-ferme" target="_blank" rel="noopener">legume.ch</a></p>
        <p> <a  href="http://www.fermebourgeon.ch" target="_blank" rel="noopener">fermebourgeon.ch</a></p>
        <br/>
        <p><strong>Quelques sites, par canton&nbsp;:</strong></p>
        <p> <a  href="http://www.terroir-fribourg.ch" target="_blank" rel="noopener">Fribourg - terroir-fribourg.ch</a></p>
        <p> <a  href="http://www.geneveterroir.ch" target="_blank" rel="noopener">Genève - geneveterroir.ch</a></p>
        <p> <a  href="http://www.terroir-juraregion.ch" target="_blank" rel="noopener">Jura - terroir-juraregion.ch</a></p>
        <p> <a  href="http://shop.labelbleu.ch/fr/neuchatel" target="_blank" rel="noopener">Neuchâtel - Label bleu</a></p>
        <p> <a  href="http://www.neuchatel-vins-terroir.ch" target="_blank" rel="noopener">Neuchâtel - neuchatel-vins-terroir.ch</a></p>
        <p> <a  href="http://www.valais-terroir.ch" target="_blank" rel="noopener">Valais - valais-terroir.ch</a></p>
        <p> <a  href="http://www.vaud-terroirs.ch " target="_blank" rel="noopener">Vaud - vaud-terroirs.ch</a></p>
        `,
        footerImage: 'image_footer/03_ORIGINE-DES-ALIMENTS-1.png'
      },
      {
        id: '4ikOc1Ea-Lg',
        footerImage: 'image_footer/03_ORIGINE-DES-ALIMENTS-1.png'
      },

      {
        id: 'leVShANKPZg',
        content: `
        <p>Pour consommer local, de saison et si possible biologique, consultez les calendriers des fruits, des légumes et des poissons de Suisse&thinsp;:</p>
        <ul>
        <li>
        <a  href="http://www.5amtag.ch/wp-content/uploads/2013/12/Saisonkalender_Gmueseverband_2013_F.pdf" target="_blank" rel="noopener">Calendrier des fruits et légumes–pdf</a>&thinsp;;
        </li>
        <li>
        <a  href="http://www.asrpp.ch/content/2-poissons-sauvages/18-calendrier-poissons-et-ecrevisses-sauvages.pdf" target="_blank" rel="noopener">Calendrier des poissons sauvages-pdf</a>.</li>
        </ul>
        <br/>
        <h4>LIENS UTILES</h4>
        <p>
        Association suisse romande des pêcheurs professionnels -
        <a  href="http://www.asrpp.ch" target="_blank" rel="noopener"><span class="mini">asrpp.ch</span></a></p>

        `,
        footerImage: 'image_footer/de_saison.png'
      },
      {
        id: '0RXAZHmMHeE',
        footerImage: 'image_footer/de_saison.png'
      },

      {
        id: 'hFsKxSvownM',
        content: `
        <h4 style="font-size: 1.3em;"><img style="
        width: 5%;
    " src="/ip_suisse.PNG" /><strong>La production intégrée (IP)</strong></h4>
        <p>Depuis 1989, le logo-coccinelle d’<strong>IP-Suisse</strong> certifie que les aliments sont produits en respectant des règles de protection de la nature et des animaux. L’utilisation d’engrais et de pesticides chimiques de synthèse est limitée ou interdite de façon partielle. Les produits génétiquement modifiés (OGM) sont interdits, et les producteurs renoncent en grande partie à l’utilisation de fongicides et de pesticides. Les animaux détenus dans les exploitations certifiées IP-Suisse peuvent vivre selon un mode de vie plus naturel, et profitent de sorties en plein air ainsi que d’un affouragement sain.</p><h4 style="font-size: 1.3em;"><img style="
        width: 7%;
    " src="/bio_suisse.PNG" /><strong>L’agriculture biologique</strong></h4>
        <p><strong>Bio Suisse</strong> et sa marque déposée <strong>Bourgeon</strong> garantissent que le producteur pratique une agriculture biologique sur l’ensemble de son exploitation. Les organismes génétiquement modifiés (OGM) sont exclus, ainsi que les pesticides, les engrais chimiques de synthèse et les additifs considérés comme inutiles (arômes, colorants). Le mode de transformation des aliments est également contrôlé. Sur d’autres points encore, le label Bio Suisse va au delà des exigences de l’Ordonnance sur l’agriculture biologique.</p>
        `,
        footerImage: 'image_footer/04_LES-MODES-DE-PRODUCTIONS-1.png'
      },
      {
        id: 'kpNCHMD_MfU',
        footerImage: 'image_footer/04_LES-MODES-DE-PRODUCTIONS-1.png'
      },

      {
        id: 'hfHCCE12hEc',
        content: `
        <h4 style="font-size: 1.3em;"><strong>Bien utiliser le réfrigérateur</strong></a></h4>
        <p>Il existe plusieurs techniques pour conserver les aliments sans réfrigérateur. Le but est de placer l'aliment dans une situation où les microorganismes ne peuvent pas survivre ni proliférer&thinsp;: </p>
        <ul>
        <li>l’<strong>appertisation</strong> (mise en conserve)&thinsp;: les aliments sont placés dans un contenant hermétiquement fermé, puis soumis à une forte chaleur&thinsp;;</li>
        <li>la <strong>déshydratation</strong>&thinsp;: l'eau est retirée par séchage, ou par évaporation sous vide lorsque le produit est congelé (lyophilisation)&thinsp;;</li>
        <li>la <strong>conservation dans l’alcool</strong> (convient très bien aux fruits)&thinsp;;</li>
        <li>la <strong>conservation dans le vinaigre</strong> (convient à certains légumes et champignons)&thinsp;;</li>
        <li>la <strong>conservation dans l’huile</strong> (pour certains légumes crus, cuits ou séchés)&thinsp;;</li>
        <li>la <strong>conservation par cuisson dans le sucre</strong> (confitures et gelées)&thinsp;;</li>
        <li>la <strong>salaison</strong> (viande, poissons, olives...)&thinsp;;</li>
        <li>le <strong>fumage</strong>, en combinaison avec le séchage et le salage (jambon, saucisse, poisson)&thinsp;;</li>
        <li>la <strong>fermentation lactique</strong> ou <strong>lacto-fermentation</strong>&thinsp;: des bactéries "mangent" partiellement le glucose de l'aliment et produisent de l'acide lactique qui permet une certaine conservation (yogourth, choucroute, olives, saucisson, etc.).</li>
        </ul>
        <p><strong >Attention&nbsp;: </strong>&nbsp;ces méthodes de conservation doivent être réalisées dans les règles de l’art, afin que l'aliment puisse être consommé sans danger. Si la préparation ne se déroule pas correctement, des bactéries pathogènes peuvent se développer dans l'aliment conservé et être la cause d’infections ou d’intoxications graves.</p>
        <br/>
        <h4>LIENS UTILES</h4>
        <p>Recommandations pour l’achat d'un appareil électroménager - <a  href="http://www.topten.ch" target="_blank" rel="noopener">topten.ch</a></p>
        `,
        footerImage: 'image_footer/05_CONSERVER-PAR-LE-FROID-1.png'
      },
      {
        id: '3tXSC09xQaw',
        footerImage: 'image_footer/05_CONSERVER-PAR-LE-FROID-1.png'
      },

      {
        id: 'X2DYJVP4fk0',
        content: `
        <h4 style="font-size: 1.3em;"><strong>Viande? Autre viande? Pas de viande?</strong></h4>
        <p>Pourquoi ne pas essayer des morceaux de viande que vous ne cuisinez jamais?</p>
        <p>Pour une alimentation durable et pour assurer une saine diversité des sources de protéines, il est recommandé de consommer au maximum une portion de viande de 100 à 120&nbsp;g, deux à trois fois par semaine.<br>
       <p><strong>Les alternatives à la viande&thinsp;: </strong></p>
        <ul>
        <li>le tofu (« fromage » de soja)&thinsp;;</li>
        <li>le seitan (à base de protéine de blé)&thinsp;;</li>
        <li>le quorn (fabriqué à base de protéines de champignon soumises à un processus de fermentation)&thinsp;;</li>
        <li>le tempeh (à base de soja fermenté)&thinsp;;</li>
        <li>les micro-algues, telle la spiruline&thinsp;;</li>
        <li>les insectes comestibles...</li>
        </ul>
        <br/>
        <h4>LIENS UTILES</h4>
        <p>
        Les morceaux oubliés -
        <a  href="https://www.frc.ch/comment-sortir-de-la-routine/" target="_blank" rel="noopener"><span class="mini">frc.ch</span></a> <br/>
        Foie, langue, rognons, les abats sont aussi délicieux à cuisiner -
        <a  href="https://www.frc.ch/abats-les-prejuges" target="_blank" rel="noopener"><span class="mini">frc.ch</span></a><br/>
        Recommandations de la Société Suisse de Nutrition -
        <a  href="http://www.sge-ssn.ch/fr/toi-et-moi/les-denrees-alimentaires/aliments/viande-poisson-oeufs-tofu/" target="_blank" rel="noopener"><span class="mini">sge-ssn.ch</span></a></p>
        </p>
        `,
        footerImage: 'image_footer/06_AUTRES-VIANDE-ET-SUBSITUTS-1.png'
      },
      {
        id: 'kQDRPCxp75s',
        footerImage: 'image_footer/06_AUTRES-VIANDE-ET-SUBSITUTS-1.png'
      },

      {
        id: 'rNfOw3nZRRA',
        content: `
        <h4 style="font-size: 1.3em;"><strong>Les légumes-racines «oubliés»</strong></h4>
        <p>On parle de «légumes oubliés» pour décrire des variétés de légumes plantés dans les siècles passés et que les exploitations agricoles n'ont plus – ou peu – produits après la guerre. Ils reviennent heureusement à la mode. Beaucoup se vendent sous forme de racines qui se conservent longtemps et qui possèdent d’excellentes propriétés nutritives. Tous ces légumes-racines se cultivent dans nos régions, en pleine terre, sans serre ni chauffage artificiel&thinsp;: </p>
        <ul>
        <li>le <strong>panais</strong>, au petit goût sucré&thinsp;;</li>
        <li>le <strong>persil tubéreux</strong>&thinsp;: ses fanes (feuilles) ont le goût du persil et sa racine rappelle le céleri-rave avec un soupçon de noisette&thinsp;;</li>
        <li>le <strong>salsifis</strong>, au goût de cœur d’artichaut&thinsp;;</li>
        <li>le <strong>rutabaga</strong> qui peut remplacer le panais ou le navet&thinsp;;</li>
        <li>les <strong>crosnes</strong>, des racines&nbsp;en forme de spirales, à la saveur fine, délicate et légèrement sucrée&thinsp;;</li>
        <li>le<strong> topinambour</strong> et l’<strong>héliantis</strong> au goût d’artichaut et de noisette, avec un peu plus de finesse pour le second&thinsp;;</li>
        <li>le <strong>chou-rave</strong> qui garde un goût de chou tout en finesse, mais évite les désagréments digestifs que peuvent amener la consommation des choux.</li>
        </ul>
        <br/>
        <h4 style="font-size: 1.3em;"><strong>Poissons de nos régions</strong></h4>
        <p>Pour se régaler avec les poissons de nos lacs et rivières, consulter le calendrier de pêche&thinsp;: </p>
        <ul>
        <li>
        <a  href="http://www.asrpp.ch/content/2-poissons-sauvages/18-calendrier-poissons-et-ecrevisses-sauvages.pdf" target="_blank" rel="noopener">Calendrier des poissons sauvages-pdf</a>
        ;</li>
        </ul>
        <br/>
        <h4>LIENS UTILES</h4>
        <p>
        Association suisse romande des pêcheurs professionnels -
        <a  href="http://www.asrpp.ch" target="_blank" rel="noopener"><span class="mini">asrpp.ch</span></a><br/>
        Sur le site de Pro Specie Rara&thinsp;: <a  href="https://www.prospecierara.ch/fr/recettes" target="_blank" rel="noopener">recettes pour cuisiner les légumes anciens</a></p>
        `,
        footerImage: 'image_footer/07_L\'EMBARRAS-DU-CHOIX-1.png'
      },
      {
        id: '9bY5COJ9t0Y',
        footerImage: 'image_footer/07_L\'EMBARRAS-DU-CHOIX-1.png'
      },

      {
        id: 'rh3DpMx4wKE',
        content: `
        <h4 style="font-size: 1.3em;"><strong>De la poubelle à l'assiette</strong></h4>

        <p>Il vous reste des <strong>pelures et des trognons de pommes ou de poires</strong> (les fruits ont bien sûr été lavés). Ne les jetez pas et lancez-vous dans la préparation de&thinsp;:</p>
        <ul>
        <li>Tisane&thinsp;: faire infuser les pelures de fruits fraîches ou séchées dans de l’eau bouillante&thinsp;;</li>
        <li>Gelée (si la quantité de déchets de fruits est suffisante)&thinsp;: les trognons, la peau et les pépins des pommes et des poires contiennent de la pectine permettant de préparer une gelée à tartiner ou à déguster dans un yogourt.
        <ul>
        <li><strong>Préparation&thinsp;: </strong><br> Placer les pelures et les trognons dans une casserole, puis couvrir d’eau jusqu'à affleurement. Ajouter le jus d’un citron. Porter à ébullition et laisser mijoter à feu très doux pendant 1&nbsp;heure. Passer l’ensemble au chinois. Peser le jus obtenu. Pour chaque litre de jus, ajouter 800&nbsp;g de sucre pour confiture. Mettre le tout dans une casserole, porter à ébullition et laisser cuire 30&nbsp;minutes environ.</li>
        </ul>
        </li>
        </ul>
        <h4 style="font-size: 1.3em;"><strong>Restes de tomate</strong></h4>
        <p>Avec un <strong>surplus de tomates qui flétrissent</strong>, préparez une base de sauce&thinsp;: Faire blanchir les tomates environ 1&nbsp;minute dans de l’eau bouillante, les peler (si la peau vous dérange), les couper en dés, et laisser refroidir avant de les congeler pour une future utilisation.</p>
        <h4 style="font-size: 1.3em;"><strong>Restes de poulet</strong></h4>
        <p>Pourquoi jeter la <strong>carcasse du poulet&nbsp;</strong>? Faites-en un <em>fond de sauce</em> en la cuisant à petit feu dans une casserole (avec un couvercle) dans un peu d’eau, quelques herbes et un oignon coupé en morceaux. Lorsque les os de la carcasse sont "nettoyés" par la vapeur, verser le bouillon chaud – sans les os – dans un petit récipient (verre, tasse, tupperware), laisser refroidir – le bouillon va se figer en une gélatine. Placer le fond de sauce au réfrigérateur (ou au congélateur) pour l'utiliser plus tard sur des pâtes ou des patates.</p>
        `,
        footerImage: 'image_footer/08_DE-BEAUX-RESTES-1.png'
      },
      {
        id: 'aOVChGCA-uo',
        footerImage: 'image_footer/08_DE-BEAUX-RESTES-1.png'
      },

      {
        id: 'tVw5_0UsSFA',
        content: `
        <h4 style="font-size: 1.3em;"><strong>Vous avez dit «déchets organiques»?</strong></h4>
        <p>Les méthodes de collecte des déchets de cuisine et des déchets de jardin (déchets dits "organiques") varient suivant les quartiers et les communes. Le mieux est de se renseigner sur le site web de sa commune (<em>nom-de-la-commune.ch</em>). Si on dispose d'un jardin, il est raisonnable de composter ses déchets sur place&thinsp;: on évite l'évacuation des déchets par camion – et on évite aussi de devoir prendre sa voiture pour aller chercher en jardinerie de quoi améliorer la fertilité de son jardin.</p>
        <ul>
        <li>
        <a  href="https://www.energie-environnement.ch/maison/jardin/compost">Faire du compost dans le jardin</a>&thinsp;;</li>
        <li>
        <a  href="https://www.energie-environnement.ch/maison/cuisine/poubelle-de-cuisine">Que recycler et que jeter dans son ménage?</a>
        </li>
        </ul>
        `,
        footerImage: 'image_footer/09_COMPOST-1.png'
      },
      {
        id: 'E5k440tqHKA',
        footerImage: 'image_footer/09_COMPOST-1.png'
      },

      {
        id: 'lLZhXoGXzX8',
        content: `
        <h4 style="font-size: 1.3em;"><strong>L'art de la cuisson avec peu d'énergie</strong></h4>
        <p>Quelques astuces pour optimiser la cuisson&thinsp;: </p>
        <ul>
        <li>
        <a  href="https://www.energie-environnement.ch/maison/cuisine/cuisson-et-casseroles/380">Cuire sans bouillir</a>&thinsp;;
        </li>
        <li>
        <a  href="https://www.energie-environnement.ch/maison/cuisine/cuisson-et-casseroles">Bien choisir et gérer ses casseroles</a>&thinsp;;
        </li>
        <li>
        <a  href="https://www.energie-environnement.ch/le-saviez-vous/448-certaines-casseroles-permettent-de-cuire-avec-deux-fois-moins-denergie">Certaines casseroles permettent de cuire avec deux fois moins d’énergie</a>&thinsp;.
      </li>
        </ul>
        <h4>LIENS UTILES<h4>
        <p>Recommandations pour l’achat d'un appareil électroménager - <a  href="http://www.topten.ch" target="_blank" rel="noopener">topten.ch</a></p>
        `,
        footerImage: 'image_footer/10_LES-MODES-DE-CUISSON-1.png'
      },
      {
        id: 's7FoiJD3iXM',
        footerImage: 'image_footer/10_LES-MODES-DE-CUISSON-1.png'
      },

      {
        id: 'AoWhUJu6BAI',
        content: `
        <h4 style="font-size: 1.3em;"> <strong>Bien utiliser son four</strong> </h4>

        <ul>
        <li>
        <a  href="https://www.energie-environnement.ch/appareils-electromenagers/four">Conseils et astuces-énergie pour l'usage du four</a>&thinsp;;
        </li>
        <li>
        <a  href="https://www.energie-environnement.ch/maison/cuisine/four-a-micro-ondes">Le four à microondes, champion de l’économie</a>.
        </li>
        </ul>
        <br/>
        <h4>LIENS UTILES</h4>
        <p>Recommandations pour l’achat d'un appareil électroménager - <a  href="http://www.topten.ch" target="_blank" rel="noopener">topten.ch</a></p>
        `,
        footerImage: 'image_footer/11_AU-FOUR-1.png'
      },
      {
        id: 'NdpohO6bXqA',
        footerImage: 'image_footer/11_AU-FOUR-1.png'
      },

      {
        id: 'N6nlAb326fw',
        content: `
        <h4 style="font-size: 1.3em;"><strong>Lave-vaisselle</strong></h4>
        <p>Tout savoir pour utiliser le lave-vaisselle en économisant l'eau et l'électricité – tout en limitant l'usage de produits polluants : </p>
        <ul>
        <li>
        <a  href="https://www.energie-environnement.ch/appareils-electromenagers/lave-vaisselle">Bien utiliser le lave-vaisselle;</a>
        </li>
        <li>
        <a  href="https://www.energie-environnement.ch/fichiers/fiches-conseils/fiche_lave-vaisselle.pdf">Mémo sur le lave-vaisselle à afficher dans la cuisine;</a>
        </li>
        <li>
        <a  href="https://www.energie-environnement.ch/le-saviez-vous/472-un-simple-debouchage-des-orifices-darrosage-peut-redonner-toute-sa-performance-a-un-lave-vaisselle">Que faire quand le lave-vaisselle lave mal.</a>
        </li>
        </ul>
        <br/>
        <h4>LIENS UTILES</h4>
        <p>Recommandations pour l’achat d'un appareil électroménager - <a  href="http://www.topten.ch" target="_blank" rel="noopener">topten.ch</a></p>
        `,
        footerImage: 'image_footer/12_A-LA-MAIN-1.png'
      },
      {
        id: 'c7081SQyCko',
        footerImage: 'image_footer/12_A-LA-MAIN-1.png'
      },

      {
        id: 'jtt-0Khjebg',
        content: `
        <h4 style="font-size: 1.3em;"><strong> À propos d'emballages</strong></h4>
        <p>Avez-vous remarqué que plus la taille d'un produit alimentaire diminue, et plus l'emballage prend de l'importance par rapport au contenu? Autrement dit, plus la portion est petite et plus il y a de déchets. Par exemple, deux bouteilles vides d'un demi-litre représentent davantage de plastique (ou de verre) qu'une seule bouteille d'un litre. Et il en va de même pour les conserves, les briques de jus et pratiquement tous les emballages.</p>
        <ul><li><a  href="https://www.energie-environnement.ch/le-saviez-vous/480-plus-les-portions-sont-petites-et-plus-il-y-a-de-dechets">Portions et déchets</a></li></ul>`,
        footerImage: 'image_footer/13_TRIER-N\'EST-PAS-RECYCLER-1.png'
      },
      {
        id: 'd122NADgXow',
        footerImage: 'image_footer/13_TRIER-N\'EST-PAS-RECYCLER-1.png'
      }
    ]
  },
  getters: {
    getItemData: (state) => (id) => {
      return state.listData.find(item => item.id === id)
    }
  }
}
