<template>
  <transition name="slide">
    <div class="back-color">
      <div class="head-back" :style="haloImageStyle">
        <NavHeader :title="config.mainTitle" :listItem="items" />
        <b-container fluid class="container-iframe mb-4 mt-4">
          <b-row>
            <b-col cols="12" sm="5" md="5" lg="5" xl="5" class="container-title">
              <div class="df d-none d-sm-block">
                <b-img :src="`${publicPath}4.png`" class="wow zoomIn" data-wow-duration="2s" data-wow-delay="4s" />
              </div>
            </b-col>
            <b-col cols="12" sm="7" md="7" lg="7" xl="7" >
              <b-embed
                :src="mainVideo"
                type="iframe"
                class="frame-video wow bounceIn"
                data-wow-duration="2s" data-wow-delay="3s"
                aspect="16by9"
                allowfullscreen
              ></b-embed>
              <p class="" data-wow-duration="2s" data-wow-delay="3s"> {{ $t('accueil.video_principal.description' ) }} </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container>
        <b-row v-if="isLoading">
          <b-col cols="12" class="align-items-center mb-4 text-center">
            <b-spinner class="ml-auto spiner-color"></b-spinner>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading">
          <b-col cols="12" class="all-video-title text-center">
            <h2> {{ $t('accueil.section_videos.titre' ) }} </h2>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            md="4"
            lg="3"
            xl="3"
            class="mb-4"
            v-for="item in items"
            :key="item.id"
          >
            <VideoCard :item="item" @click="onClick" />
          </b-col>
        </b-row>
      </b-container>
      <Footer footerImage="5.png" />
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { config } from '../config.js'
import { mapMutations } from 'vuex'
import Footer from '../components/Footer'
import VideoCard from '../components/VideoCard'
import NavHeader from '../components/NavHeader'
export default {
  name: 'home',
  components: { Footer, VideoCard, NavHeader },
  data () {
    return {
      isLoading: true,
      playListItems: {},
      publicPath: process.env.BASE_URL
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
    '$i18n.locale': 'fetchData'
  },
  computed: {

    currentUrl () {
      return encodeURI(location.href)
    },
    haloImageStyle () {
      return {
        // "background-size": "37% 12%",
        // "background-repeat": "no-repeat",
        // "background-position": "right top",
        'background-image': `url(${this.publicPath}fond_1.jpg)`
      }
    },
    config () {
      return config
    },
    items () {
      if (this.playListItems && this.playListItems.items) {
        return this.playListItems.items.filter(item => {
          return item.snippet.position !== 0
        })
      }
      return null
    },
    mata_title () {
      return `${this.$t('accueil.meta.title')} | Energie-environement.ch`
    },
    meta_description () {
      return 'Cette série de 14 vidéos traite de l’impact énergétique et environnemental de notre alimentation, comme par exemple le gaspillage alimentaire. Mais les solutions existent ! Ces vidéos montrent ce que nous pouvons faire au quotidien pour améliorer la situation.'
    },
    mainVideo () {
      return this.config.mainVideos[this.$i18n.locale]
    }
  },
  metaInfo () {
    return {
      title: this.mata_title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta_description
        },
        {
          property: 'og:title',
          content: this.mata_title
        },
        {
          property: 'og:description',
          content: this.meta_description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: `${this.currentUrl}og-image.png`
        },
        {
          property: 'og:url',
          content: this.currentUrl
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['SET_ITEM']),
    fetchData () {
      this.isLoading = true
      this.$http({
        method: 'get',
        url: 'playlistItems',
        crossDomain: true,
        params: {
          key: config.apiKey,
          part: config.part.snippet,
          fields: config.playListFields,
          playlistId: config.playlistIds[this.$i18n.locale],
          maxResults: config.maxResults
        }
      })
        .then(response => {
          this.playListItems = response.data
          this.playListItems.items.forEach(element => {
            this.$set(element, 'isOver', false)
          })
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onClick (item) {
      this.SET_ITEM(item)
      this.$Progress.start()
      let slug = item.snippet.title
      slug = slug.toLowerCase().replace(/ |’|'/g, '-').replace(/à/g, 'a')
      this.$router.push({
        name: 'video',
        params: {
          id: item.snippet.resourceId.videoId,
          slug: slug
        }
      })
    }
  }
}
</script>

<style  scoped  lang="scss">
@import "./src/styles/mainStyle.scss";
.home-head {
  margin: auto;
}

.head-back{
  background-size: cover;
}

.family-zilla {
  font-family: $familyZilla;
}
.col-left h4,
.col-right h4 {
  color: white;
  background: $titleColor;
  display: inline;
  font-size: 1.5em;
  padding: 10px;

  font-family: $familyZilla;
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    display: block;
  }
}

.container-title {
  display: flex;
  align-items: flex-end;
  h3 {
    color: $titleColor;
    font-size: 2.9em;

    @include family-zilla;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      font-size: 1.7em;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      transform: rotate(0deg);
      font-size: 2.7em;
    }
  }
}
.df {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-top: 0px;
  }
}
.all-video-title {
  font-size: 2em;
  color: $titleColor;
  margin-top: 0px;
  margin-bottom: 15px;
  @include family-zilla;
}
.df img {
  width: 83%;
}
.container-iframe p {
  color: white;
  font-size: 1.5em;
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: center;
  @include family-zilla;
}

.col-right .first-p {
  background: #f4f4f4;
  margin-top: 30px;
}
.second-title {
  color: white;
  background: $titleColor;
  display: inline;
  font-size: 2em;
  padding: 10px;
  text-indent: unset;
  font-family: familyZilla;
}
.third-contenair {
  background: #f4f4f4;
  padding-top: 30px;
  padding-bottom: 30px;
}
.btn-plus {
  color: white;
  background: $titleColor;
  border-radius: 70px;
}
.third-contenair h6 {
  color: #9e9c9c;
}
</style>
