import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/video/:id/:slug',
      name: 'video',
      component: () => import(/* webpackChunkName:'video' */'./views/PlayVideo.vue'),
      props: true
    },
    {
      path: '/a-propos',
      name: 'about',
      component: () => import(/* webpackChunkName:'about' */'./views/About.vue'),
      props: true
    },
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName:'notfound' */'./views/NotFound.vue'),
      props: true
    }
  ]
})
