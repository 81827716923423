export const config = Object.freeze({
  apiKey: 'AIzaSyDty0do0rxXpX4C5CYY4Msi82AnS9-uhgw',
  part: {
    snippet: 'snippet',
    statistics: 'statistics'
  },
  maxResults: 50,
  playlistIds: {
    fr: 'PLudH69j9x85KJCgQSY6x6GqQd6DKr18Zr',
    de: 'PLudH69j9x85Lvhdqog5g_1aoe0Ugiqhqt'
  },
  mainVideos: {
    fr: 'https://www.youtube.com/embed/FCFmbJe3YWE?rel=0',
    de: 'https://www.youtube.com/embed/W-wTFnpL5-M'
  },
  playListFields: 'etag,eventId,items,kind,nextPageToken,pageInfo,prevPageToken,tokenPagination,visitorId',
  videoFields: 'eventId,items(contentDetails/duration,fileDetails/durationMs,id,snippet(categoryId,channelId,channelTitle,defaultAudioLanguage,defaultLanguage,description,publishedAt,tags,thumbnails,title),statistics)',
  mainTitle: 'VRAIMENT DURABLE,<br/> MON ALIMENTATION?',
  homeTitle: 'VRAIMENT DURABLE, MON ALIMENTATION?'
})
