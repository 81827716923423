import Vue from 'vue'
import Vuex from 'vuex'
import Item from './store/selectedItem'
import videoData from './store/videodata'
import animationState from './store/animationState'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    selectedItem: Item,
    videoData: videoData,
    animationState: animationState
  }
})
